<template>
  <div :class="`knapsack_${$store.state.mode}`">
    <template v-if="$store.state.mode == 'pc'">
      <div class="btn_wrap">
        <button @click="openConfirmDelivery">
          <i class="el-icon-s-home"></i>
          <span>提取物品</span>
        </button>
        <button @click="openConfirm">
          <i class="el-icon-s-home"></i>
          <span>分解物品</span>
        </button>
        <div class="form_wrap form">
          <div class="form_item">
            <el-select v-model="selected" placeholder="请选择" @change="handleSort">
              <el-option v-for="item in selectOptions" :key="item.label" :label="item.label" :value="item.label" />
            </el-select>
          </div>
          <div class="form_item">
            <el-input placeholder="请输入饰品名称" v-model="page.name" @change="handleSearch">
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>
        </div>
        <div class="all_select">
          <p @click="handleSelectAll">全选<span :class="['all_btn', { active: isAll }]"></span></p>
          <p>已选物品：{{ isAll ? totalOrnamentNumber : selectData.count }}</p>
          <price :price="isAll ? totalOrnamentPrice : selectData.price" size="middle" />
        </div>
      </div>
      <div class="knapsack_list" v-if="knapsackList.length">
        <goodsCard v-for="(item, index) in knapsackList" :key="index" :goodsItem="item" :isCheck="true" :keyMap="{ price: 'staticPrice' }" @handleClick="handleSelect" />
      </div>
      <div class="not_data" v-else>--暂无饰品--</div>
      <el-pagination background layout="prev, pager, next" :total="listTotal" :current-page="page.page" :page-size="page.size" @current-change="queryPackSack" :hide-on-single-page="true" />
    </template>
    <template v-else>
      <headerTop title="我的背包" />
      <div class="header column">
        <div class="header_I left">
          <div class="header_I_I">饰品总数 : {{ totalOrnamentNumber || 0 }}</div>
          <div class="header_I_I">饰品总价值 : {{ totalOrnamentPrice }}</div>
        </div>
        <div class="header_I left">
          <div class="header_I_I">已选饰品总数 : {{ isAll ? totalOrnamentNumber : selectData.count }}</div>
          <div class="header_I_I">已选饰品总价值 : {{ isAll ? totalOrnamentPrice : selectData.price }}</div>
        </div>
      </div>
      <div class="main column">
        <div class="main_I space_between">
          <div class="main_I_I center" @click.stop="showOption = !showOption">
            {{ selected }}
            <div class="main_I_I_I" v-if="showOption">
              <div :class="['main_I_I_I_I center', { select: selected == item.label }]" v-for="item in selectOptions" :key="item.label" @click.stop="handleSort(item.label)">{{ item.label }}</div>
            </div>
          </div>
          <div class="main_I_II">
            <div class="main_I_II_I">
              <input type="text" v-model="page.name" placeholder="请输入饰品名称" />
              <i @click="handleSearch" class="icon el-icon-search"></i>
            </div>
          </div>
        </div>
        <div class="main_II space_between">
          <div class="main_II_I space_between">
            <div class="main_II_I_I center btn_click" @click="openConfirmDelivery">提取</div>
            <div class="main_II_I_I center btn_click" @click="openConfirm">分解</div>
          </div>
          <div class="main_II_II space_between">
            <div class="main_II_II_I space_between" @click="handleSelectAll" :class="isAll ? 'active' : ''">
              <svg t="1707113229515" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3034">
                <path
                  d="M673.858 151.948H198.194C88.903 151.948 0 240.852 0 350.142v475.665C0 935.097 88.903 1024 198.194 1024h475.665c109.29 0 198.194-88.903 198.194-198.194V350.142c-0.001-109.29-88.905-198.194-198.195-198.194z m21.419 320.917l-286.283 286.27c-7.742 7.742-17.884 11.613-28.026 11.613s-20.284-3.871-28.026-11.613L176.774 582.968c-15.484-15.471-15.484-40.581 0-56.052 15.484-15.484 40.568-15.484 56.052 0l148.142 148.142 258.258-258.245c15.484-15.484 40.568-15.484 56.052 0 15.483 15.484 15.483 40.581-0.001 56.052z"
                  fill="#727277"
                  p-id="3035"
                />
                <path d="M825.806 0H350.142c-73.597 0-137.813 40.405-171.997 100.113 6.634-0.529 13.281-1.016 20.048-1.016h475.665c138.426 0 251.045 112.619 251.045 251.045v475.665c0 6.768-0.487 13.414-1.016 20.048C983.595 811.671 1024 747.455 1024 673.858V198.194C1024 88.903 935.097 0 825.806 0z" fill="#727277" p-id="3036" />
              </svg>
              全选
            </div>
            <div class="main_II_II_II">已选：{{ isAll ? totalOrnamentNumber : selectData.count }}</div>
          </div>
        </div>
      </div>
      <div class="goods_wrap" v-if="knapsackList.length" @scroll="handleScroll">
        <div class="goods_list">
          <goodsCard v-for="(item, index) in knapsackList" :key="index" :goodsItem="item" :isCheck="true" :keyMap="{ price: 'staticPrice' }" @handleClick="handleSelect" />
        </div>
        <div class="over_text">{{ isOver }}</div>
      </div>
      <div class="not_data" v-else>--暂无数据--</div>
    </template>
  </div>
</template>

<script>
import { getPackSack, decompose, delivery, getUserInfo, packSackGlobalData } from '@/api/index';
import goodsCard from '@/components/goodsCard';

export default {
  data() {
    return {
      isSubmit: true,
      showOption: false,
      searchTerm: '',
      selected: '默认排序',
      selectOptions: [{ label: '默认排序' }, { label: '价格（从低到高）' }, { label: '价格（从高到低）' }],
      knapsackList: [],
      isAll: false,
      page: {
        page: 1,
        size: 14,
        orderByFie: 1,
        orderByType: 2,
        name: '',
      },
      isOver: '上拉加载更多',
      totalOrnamentNumber: 0,
      totalOrnamentPrice: 0,
      listTotal: 0,
    };
  },
  components: { goodsCard },
  computed: {
    selectList() {
      return (this.knapsackList || []).filter(item => item.isSelect);
    },
    selectData() {
      let price = 0;
      let count = 0;
      this.knapsackList.forEach(item => {
        if (item.isSelect) {
          price += item.staticPrice * 1;
          count++;
        }
      });
      return { price: price.toFixed(2), count };
    },
  },
  mounted() {
    this.queryPackSack(1);
    this.getGlobalData();
  },
  methods: {
    playAudio() {
      this.$store.commit('playAudio');
    },
    handleScroll(e) {
      const { scrollTop, clientHeight, scrollHeight } = e.srcElement;
      if (scrollTop + clientHeight + 5 >= scrollHeight-1) {
        if (this.isOver == '上拉加载更多') {
          this.page.page++;
          let timer = setTimeout(() => {
            this.queryPackSack();
            clearTimeout(timer);
          }, 5);
        }
      }
    },
    openConfirm() {
      this.playAudio();
      if (!this.selectList.length) {
        this.$message({
          offset: 50,
          message: '请先选择饰品再操作',
          type: 'warning',
        });
      } else {
        this.$confirm(this.isAll ? '确定清空背包吗' : `确定要分解${this.selectList.length}件物品吗？`, '提示', {
          confirmButtonText: this.isSubmit ? '是' : <i class="el-icon-loading"></i>,
          cancelButtonText: '否',
          cancelButtonClass: 'active_btn',
          center: true,
        })
          .then(() => {
            this.handleDecompose();
          })
          .catch(() => {
            this.playAudio();
          });
      }
    },
    openConfirmDelivery() {
      this.playAudio();
      if (!this.selectList.length) {
        this.$message({
          offset: 50,
          message: '请先选择饰品再操作',
          type: 'warning',
        });
      } else {
        this.$confirm(this.isAll ? '确定提取背包内所有物品吗' : `确定要提取${this.selectList.length}件物品吗？`, '提示', {
          confirmButtonText: this.isSubmit ? '是' : <i class="el-icon-loading"></i>,
          cancelButtonText: '否',
          cancelButtonClass: 'active_btn',
          center: true,
        })
          .then(() => {
            this.handledelivery();
          })
          .catch(() => {
            this.playAudio();
          });
      }
    },
    handledelivery() {
      this.playAudio();
      if (!this.selectList.length) {
        this.$message({
          offset: 50,
          message: '请先选择饰品再操作',
          type: 'warning',
        });
      } else {
        const packSackIds = this.selectList.map(item => item.id);
        delivery({ isAll: this.isAll, packSackIds }).then(res => {
          if (res?.data?.code == 200) {
            this.$message({
              offset: 50,
              message: res.data.msg,
              type: 'success',
            });
            if (this.isAll) {
              this.isAll = false;
              this.knapsackList = [];
            } else {
              if (this.knapsackList.length < this.page.size) {
                const list = this.knapsackList.filter(item => !packSackIds.includes(item.id));
                this.knapsackList = [...list];
              } else {
                this.queryPackSack(1);
              }
            }
            this.getGlobalData();
          } else {
            if (res.data.msg == '您的帐号提货功能已被禁用，请联系管理员！') {
              this.$message({
                offset: 50,
                message: '账户异常，请联系管理员',
                type: 'warning',
              });
            } else {
              this.$message({
                offset: 50,
                message: res.data.msg,
                type: 'warning',
              });
            }
          }
        });
      }
    },
    handleSort(res) {
      this.playAudio();
      this.selected = res;
      this.page.orderByFie = res == '默认排序' ? 1 : 2;
      this.page.orderByType = res == '价格（从低到高）' ? 1 : 2;
      this.showOption = false;
      this.queryPackSack(1);
    },
    handleSearch() {
      this.playAudio();
      this.page.orderByFie = 1;
      this.page.orderByType = 2;
      this.queryPackSack(1);
    },
    handleSelect(res) {
      res.isSelect = !res.isSelect;
      this.isAll = this.selectList.length == this.totalOrnamentNumber;
    },
    handleSelectAll() {
      this.playAudio();
      this.isAll = !this.isAll;
      for (let i = 0; i < this.knapsackList.length; i++) {
        this.knapsackList[i].isSelect = this.isAll;
      }
    },
    handleDecompose() {
      this.playAudio();
      this.isSubmit = false;
      const packSackIds = this.selectList.map(item => item.id);
      decompose({ isAll: this.isAll, packSackIds }).then(res => {
        if (res?.data?.code === 200) {
          let timer = setTimeout(() => {
            this.$message({
              offset: 50,
              message: '分解成功',
              type: 'success',
            });
            getUserInfo().then(res => {
              this.user = res?.data?.data || {};
              this.$store.commit('USER_INFO', this.user);
              clearTimeout(timer);
            });
            this.isSubmit = true;
          }, 400);

          if (this.isAll) {
            this.isAll = false;
            this.knapsackList = [];
          } else {
            if (this.knapsackList.length < this.page.size) {
              const list = this.knapsackList.filter(item => !packSackIds.includes(item.id));
              this.knapsackList = [...list];
            } else {
              this.queryPackSack(1);
            }
          }
          this.getGlobalData();
        }
      });
    },
    getGlobalData() {
      packSackGlobalData().then(res => {
        const { totalOrnamentPrice, totalOrnamentNumber } = res?.data?.data || {};
        this.totalOrnamentPrice = (totalOrnamentPrice || 0).toFixed(2);
        this.totalOrnamentNumber = totalOrnamentNumber || 0;
      });
    },
    queryPackSack(page) {
      this.isOver = '加载中';
      if (page) this.page.page = page;
      if (page == 1) this.knapsackList = [];
      getPackSack(this.page).then(res => {
        const { list = [], total } = res?.data?.data || {};
        const arr = list.map(item => ({ ...item, isSelect: this.isAll || this.selectList.includes(item.id) }));
        this.listTotal = total || 0;
        if (this.$store.state.mode == 'pc') {
          this.knapsackList = [...arr];
        } else {
          this.knapsackList.push(...arr);
          this.isOver = this.page.size > list.length ? '暂无更多' : '上拉加载更多';
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.knapsack_pc {
  margin: 0 auto;
  .btn_wrap {
    .flex-a-center;
    & > button {
      .sc(16px, #fff);
      .btn-style(140px, 38px);
      margin-right: 20px;
      border-radius: 18px;
      span {
        padding-left: 4px;
      }
    }
    .all_select {
      margin-left: auto;
      .flex-a-center;
      & > p {
        .flex-a-center;
        .sc(16px, #fff);
        &:nth-of-type(2) {
          margin: 0 20px;
        }
        .all_btn {
          .wh(14px, 14px);
          display: block;
          margin: 1px 0 0 4px;
          border: 1px solid @bd2;
          &.active {
            position: relative;
            border-color: @main;
            &::after {
              content: '';
              .abs-ctl;
              .wh(6px, 6px);
              background: @main;
            }
          }
        }
      }
    }
  }
  .knapsack_list {
    .grid(7, 10px);
    margin-bottom: 48px;
    ::v-deep .weapon-item {
      width: 200px;
    }
  }
}
.knapsack_mobile {
  left: 0;
  right: 0;
  .flex-column;
  top: @headerH;
  bottom: @footerH;
  position: absolute;
  .header {
    height: 100px;
    font-size: 14px;
    background: #101115;
    border-radius: 14px;
    overflow: hidden;
    margin: 0 @mSpace;
    .header_I {
      width: 100%;
      height: 50px;
      padding: 0 20px;
      box-sizing: border-box;
      justify-content: space-between;
      border-bottom: 1px solid #323436;
      .flex-a-center;
      &:last-of-type {
        border-bottom: none;
      }
    }
  }
  .main {
    margin: 14px @mSpace 0;
    .main_I {
      width: 100%;
      .main_I_I {
        width: 160px;
        height: 30px;
        font-size: 12px;
        background: #323436;
        border-radius: 70px 70px 70px 70px;
        opacity: 1;
        position: relative;
        .main_I_I_I {
          width: 100%;
          z-index: 9;
          position: absolute;
          top: 34px;
          background: #101115;
          .main_I_I_I_I {
            width: 100%;
            height: 30px;
            opacity: 1;
            font-size: 12px;
            color: #727477;
            &:hover {
              .btn-bg;
              color: #fff;
              opacity: 1;
            }
            &.select {
              color: @main;
            }
          }
          .active {
            .btn-bg;
            opacity: 1;
          }
        }
      }
      .main_I_II {
        flex: 1;
        margin-left: 10px;
        .main_I_II_I {
          width: 100%;
          height: 30px;
          padding: 0 4px;
          background: #323436;
          box-sizing: border-box;
          border-radius: 50px;
          overflow: hidden;
          opacity: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          input {
            width: 100%;
            height: 100%;
            border-radius: 35px;
            border: 1px solid #323436;
            color: #fff;
            font-size: 14px;
            background-color: #323436;
            text-indent: 10px;
            outline: none;
          }
          .icon {
            padding: 5px;
            font-size: 16px;
          }
        }
      }
    }
    .main_II {
      width: 100%;
      margin: 14px 0;
      .main_II_I {
        width: 160px;
        .main_II_I_I {
          border-radius: 35px;
          opacity: 1;
          border: 1px solid #727477;
          font-weight: bold;
          color: #727477;
          width: 70px;
          height: 25px;
          font-size: 14px;
        }
      }
      .main_II_II {
        width: 45%;
        justify-content: right;
        font-size: 14px;
        .main_II_II_I {
          width: 44%;
          .icon {
            width: 16px;
            height: 16px;
          }
        }
        .active {
          svg {
            path {
              fill: @main;
            }
          }
        }
        .main_II_II_II {
          padding: 0 10px;
        }
      }
    }
  }
  .goods_wrap {
    flex: 1;
    overflow: hidden;
    overflow-y: auto;
    padding: 0 @mSpace;
    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background: @main;
      border-radius: 5px;
    }
    .goods_list {
      .grid(3, 6px);
    }
  }
}
.out {
  .fixed_bg;
  z-index: 2;
  .out_I {
    width: 40%;
    margin: 0 auto;
    margin-top: 20%;
    background: #101115;
    border-radius: 21px;
    opacity: 1;
    text-align: center;
    font-weight: 400;
    color: #ffffff;
    position: relative;
    overflow: hidden;
    font-size: 15px;
    height: 200px;
    padding-top: 25px;
    @media @max750 {
      width: 95%;
      height: 120px;
      margin-top: 210px;
    }
    .out_I_I {
      width: 87%;
      margin: 0 auto;
      margin-top: 35px;
      position: relative;
      z-index: 2;
      .out_I_I_I {
        background: linear-gradient(360deg, rgba(114, 116, 119, 0.5) 0%, rgba(114, 116, 119, 0.25) 100%);
        opacity: 1;
        border: 1px solid #727477;
        font-size: 15px;
        width: 140px;
        height: 30px;
      }
      .out_I_I_II {
        .btn-bg;
        font-size: 15px;
        width: 140px;
        height: 30px;
        opacity: 1;
      }
    }
  }
  .out_I::before {
    content: ''; /*必须设置content属性*/
    width: 100%;
    height: 100%;
    background: linear-gradient(-219deg, rgba(@main, 0.2) 0%, rgba(32, 29, 48, 0) 40%, rgba(248, 125, 81, 0) 100%);
    position: absolute;
    left: 0;
    top: 0;
  }
}
</style>
